import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../layout/layout';
import { Blades } from '../common/blades/Blades';
import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { TopNav } from '../layout';
import { MainFooter } from '../layout/footer/footer.interfaces';
import { GlobalStrings } from '../../common/fragments/MRKConfig';
import { ContentfulPage } from '../../contentful/content-types/page/page.interface';
import { ContentfulNavigation } from '../../contentful/content-types/navigation';

export const query = graphql`
  query($locale: String!) {
    page(relativePath: { eq: "securityspotlight" }, locale: { eq: $locale }) {
      ...securitySpotlightFragment
    }
    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    navAbTestData: mainTopNav(
      contentfulId: { eq: "5g5R508U5ykm1WoKe5Q366" }
      locale: { eq: $locale }
    ) {
      ...mainTopNavAbTestFragment
    }
    globalStrings: mrkConfigStaticStrings(
      contentfulId: { eq: "1uP0ac1EPF1qsviUi4I9Vz" }
      locale: { eq: $locale }
    ) {
      ...GlobalStringsFragment
    }
  }
`;
export interface SecuirtyPageParams extends GatsbyTemplateProps<AppContextProps> {
  data: {
    page: ContentfulPage;
    navData: TopNav;
    redwoodNavData: ContentfulNavigation;
    redwoodRightNavData: ContentfulNavigation;
    utilityNavData: ContentfulNavigation;
    footerData: MainFooter;
    navAbTestData: TopNav;
    globalStrings: GlobalStrings;
  };
}

const SecurityPage: FC<SecuirtyPageParams> = ({ data, pageContext }) => {
  return (
    <Layout
      redwoodNavData={data.redwoodNavData}
      redwoodRightNavData={data.redwoodRightNavData}
      utilityNavData={data.utilityNavData}
      pageContext={pageContext}
      topNavData={data.navData}
      topNavAbTestData={data.navAbTestData}
      footerData={data.footerData}
    >
      <Helmet>
        <title>Cloudflare</title>
      </Helmet>
      {data.page.relatedBlades && (
        <Blades blades={data.page.relatedBlades} globalStrings={data.globalStrings}></Blades>
      )}
    </Layout>
  );
};

export default SecurityPage;
